import React, { Component } from "react";
import { css } from "@emotion/react";

import { graphql } from "gatsby";
import * as Styles from "../styles";
import * as Libs from "libs";
import * as Modules from "../components/modules";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default class PostWorks extends Component {
	//--------------------------------------
	//  Styles
	//--------------------------------------

	articleHeaderCss = () => {
		let lessPabCss = null;
		if (this.state.ready) {
			lessPabCss = css`
				height: ${this.articleHeader.clientHeight}px;
			`;
		}
		return css`
			${Styles.Mq.lessPab} {
				${lessPabCss}
			}
		`;
	};
	blocks = () =>
		css`
			margin-top: 10vw;

			margin-left: auto;
			margin-right: auto;
		`;

	imgContCss = (aspectRatio) => {
		const heightValue = this.state.imgContHeight;
		if (aspectRatio <= 1) {
			return css`
				height: 100vh;
				${Styles.Mq.moreTab} {
					height: 100vh;
					margin-left: auto;
					margin-right: auto;
					.gatsby-image-wrapper {
						height: 100%;
						img {
							object-fit: contain !important;
						}
					}
				}
				${Styles.Mq.lessPab} {
					height: ${heightValue}px;
					.gatsby-image-wrapper {
						height: ${heightValue}px;
					}
				}
			`;
		}
	};

	inlineAncher = () =>
		css`
			${Styles.Mixin.textAncher()}
		`;
	//--------------------------------------
	//  Scripts
	//--------------------------------------

	constructor(props) {
		super(props);

		this.articleHeader = React.createRef();
		/*
    this.imgSrc = WindowResize.currentType(WindowResize.lessPab)
      ? works.headerImage.imageSp
        ? works.headerImage.imageSp
        : works.headerImage.image
      : works.headerImage.image
    this.ratio = this.imgSrc.sizes.aspectRatio*/
		this.state = {
			ready: false,
			display: false,
		};
	}

	componentDidMount() {
		Libs.Common.Config.excuteTransition();
	}
	shouldComponentUpdate(nextProps, nextState) {
		return false;
	}
	render() {
		const { data, pageContext } = this.props;
		const works = data.contentfulContentWorks;
		const { next, prev } = pageContext;
		const lang = works.node_locale;
		let schemaOrgJSONLDList = [];

		schemaOrgJSONLDList.push(
			Libs.Common.SEO.getBreadcrumbSchemaOrgJSONLD(
				[
					{
						name: "Home",
						url: `/${works.category.node_locale}/`,
					},
					{
						name: "Works",
						url: `/${works.category.node_locale}/${works.category.slug}/`,
					},
					{
						name: works.name,
						title: works.title,
						url: `/${works.category.node_locale}/${works.category.slug}/${works.slug}/`,
					},
				],
				{
					next: `/${lang}/${works.category.slug}/${next.slug}/`,
					prev: `/${lang}/${works.category.slug}/${prev.slug}/`,
				}
			)
		);

		return (
			<>
				<Libs.Common.SEO.SEO
					title={works.title ? works.title : works.name}
					lang={lang}
					schemaOrgJSONLD={schemaOrgJSONLDList}
					description={
						works.description
							? documentToReactComponents(JSON.parse(works.description.raw))[0]
									.props.children[0]
							: works.title
					}
					ogImage={works.thumbnail ? works.thumbnail.fluid.src : null}
					link={`${works.category.slug}/${works.slug}`}
				/>
				<section
					className="article project-container"
					data-prev={prev.slug}
					data-next={next.slug}
					data-current={works.slug}
					data-currentname={works.name}
					data-categoryname={works.category.name}
					data-categoryslug={works.category.slug}
					data-type={"post"}
				>
					<article className="container content">
						<Modules.StoryElement
							story={works.story}
							next={next.slug}
							prev={prev.slug}
							category={works.category}
						/>
					</article>
				</section>
			</>
		);
	}
}

export const pageQuery = graphql`
	query artworkQuery($id: String!) {
		site {
			siteMetadata {
				languages {
					defaultLangKey
					langs
				}
			}
		}
		contentfulContentWorks(id: { eq: $id }) {
			node_locale
			slug
			name
			title

			date(formatString: "MMM Do, YY")

			description {
				raw
			}
			thumbnail {
				fluid(maxWidth: 400, quality: 70) {
					...GatsbyContentfulFluid_withWebp
				}
			}
			story {
				items {
					... on ContentfulElementImage {
						...Image
					}
				}
			}

			category {
				name
				slug
			}
			tags {
				name
				slug
			}
		}
	}
`;
